import types from './types'

const initialState = {
    roomId: localStorage.getItem('roomId') || '', // ''
    view: 'start', // start, lobby, mainView
    players: [],
    hostId: '',
}

function gameReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_ROOM_ID: {
            return {
                ...state,
                roomId: action.data,
            }
        }
        case types.UPDATE_GAME_STATE: {
            return {
                ...action.data,
            }
        }
        default: {
            return state
        }
    }
}

export default gameReducer
