import { createSelector } from 'reselect'
import { getSocketId } from 'state/user/selectors'

export const getRoomId = state => state.game.roomId

export const getGameView = state => state.game.view

export const getPlayers = state => state.game.players

export const getHostId = state => state.game.hostId

export const getRoundState = state => state.game.roundState

export const getCurrentPlayer = state =>
    state.game.players[state.game.currentPlayer]

export const getCurrentMemePath = state => state.game.currentMemePath

export const getMyself = createSelector(
    [getPlayers, getSocketId],
    (players, ownSocketId) => {
        return players.find(player => player.socketId === ownSocketId)
    }
)

const getCurrentPlayerIndex = state => state.game.currentPlayer

// return all players that are not current player, current player is jury and thats why not playing
export const getPlayingPlayers = createSelector(
    [getPlayers, getCurrentPlayerIndex],
    (players, currentIndex) => {
        return players.filter((_, index) => index !== currentIndex)
    }
)

export const getAmIJury = createSelector(
    [getCurrentPlayer, getSocketId],
    (currentPlayer, ownSocketId) => {
        return currentPlayer.socketId === ownSocketId
    }
)

export const getPlayersWithMissingSelection = createSelector(
    [getPlayingPlayers],
    playingPlayers => playingPlayers.filter(player => !player.selectedCard)
)

const getWinningId = state => state.game.winningPlayerId

export const getWinningPlayer = createSelector(
    [getPlayers, getWinningId],
    (players, winnerId) => {
        return players.find(player => player.socketId === winnerId)
    }
)

export const getLastWinner = state => state.game.lastWinner

export const getLastWinningCard = state => state.game.lastWinningCard

export const getPreviousMemePaths = state => state.game.previousMemePaths
