import io from 'socket.io-client'
import { getGameView, getRoomId } from 'state/game/selectors'
import { updateIsLoading } from 'state/loading/actions'
import store from 'state/store'
import { updateGameState } from 'state/game/actions'
import { setSocketId, updateConnectionStatus } from 'state/user/actions'
import { getSocketId } from 'state/user/selectors'

export const constants = {
    clientType: {
        start: 'start',
        selectCard: 'selectCard',
        skiMeme: 'skipMeme', // do later after hackathon
        chooseWinning: 'chooseWinning',
        moveUp: 'moveUp',
        startNextTurn: 'startNextTurn',
    },
}

function lowerBackgroundOpacity() {
    const bg = window.document.querySelector('.background-image')
    bg.classList.add('low-opacity')
}

function hideLinks() {
    const linksWrapper = window.document.querySelector('.links-wrapper')

    if (linksWrapper) {
        linksWrapper.remove()
    }
}

class WebSocketService {
    constructor() {
        this._initiated = false
        this._socket = null

        this.mode = ''
        this.username = ''
        this.roomId = ''

        this.retries = 0
    }

    reconnect() {
        console.log(this.retries)
        if (this.retries >= 3) {
            alert('connection lost. page will reload')
            window.location.href = ''
            return
        }
        setTimeout(() => {
            this.retries += 1
            const roomId = getRoomId(store.getState())
            this.connect('join', this.username, roomId)
        }, 2000)
    }

    /**
     * connects to server and attaches listeners
     * @param {String} mode - create or join
     * @param {String} username
     * @param {String} roomId
     */
    connect(mode, username, roomId) {
        const isLocal = window.location.href.includes('localhost')
        const websocketHost = isLocal
            ? 'http://localhost:52432'
            : 'https://memep.uber.space/'

        this._socket = io(websocketHost, {
            query: {
                mode,
                username,
                roomId,
                socketId: getSocketId(store.getState()),
            },
            reconnection: false,
        })

        this.mode = mode
        this.username = username
        this.roomId = roomId

        window.testsocket = this._socket // for debugging

        this._socket.on('connect', () => {
            this._initiated = true
            this.retries = 0
            store.dispatch(updateConnectionStatus(true))
            console.log('connected')
        })

        this._socket.on('connect_failed', e => {
            this.reconnect()
        })
        this._socket.on('connect_error', e => {
            this.reconnect()
        })

        this._socket.on('message', data => {
            const msg = JSON.parse(data)

            store.dispatch(updateIsLoading(false))
            if (msg.ws_type === 'gameState') {
                const data = msg.data
                if (data.roomId) {
                    localStorage.setItem('roomId', data.roomId)
                }

                store.dispatch(updateGameState(data))
            }

            if (msg.ws_type === 'socketId') {
                lowerBackgroundOpacity()
                hideLinks()
                store.dispatch(setSocketId(msg.data))
            }

            if (msg.ws_type === 'error') {
                alert(msg.data)
            }
        })

        this._socket.on('disconnect', () => {
            const view = getGameView(store.getState())
            console.log('disconnected')
            if (view !== 'start') {
                store.dispatch(updateConnectionStatus(false))

                this.reconnect()
            }
        })
    }

    emit(type, data) {
        if (!this._socket) {
            alert('this state should never happen, WebSocketService.emit()')
        }
        store.dispatch(updateIsLoading(true))

        this._socket.emit('message', {
            client_type: type,
            data,
        })
    }
}

export default new WebSocketService()
