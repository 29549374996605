import React from 'react'
import { useSelector } from 'react-redux'
import { getGameView } from 'state/game/selectors'
import Start from './views/Start'
import Lobby from './views/Lobby'
import MainView from './views/MainView'

const Game = () => {
    const gameView = useSelector(getGameView)
    if (gameView === 'start') {
        return <Start />
    }

    if (gameView === 'lobby') {
        return <Lobby />
    }

    if (gameView === 'mainView') {
        return <MainView />
    }
    return null
}

export default Game
