import types from './types'

const initialState = {
    username: localStorage.getItem('username') || '',
    socketId: localStorage.getItem('socketId') || '',
    connected: false,
}

function userReducer(state = initialState, action) {
    if (action.type === types.SET_USERNAME) {
        localStorage.setItem('username', action.data)
        return {
            ...state,
            username: action.data,
        }
    }
    if (action.type === types.SET_SOCKET_ID) {
        localStorage.setItem('socketId', action.data)
        return {
            ...state,
            socketId: action.data,
        }
    }
    if (action.type === types.UPDATE_CONNECTION_STATUS) {
        return {
            ...state,
            connected: action.data,
        }
    }
    return state
}

export default userReducer
