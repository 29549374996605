import React from 'react'
import GlobalStyles from 'styles/GlobalStyles'
import AppRouter from 'AppRouter'

function App() {
    return (
        <React.Fragment>
            <GlobalStyles />
            <AppRouter />
        </React.Fragment>
    )
}

export default App
