import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Game from 'pages/game/Game'

function AppRouter() {
    return (
        <Router>
            <Route path="/" exact component={Game} />
        </Router>
    )
}

export default AppRouter
