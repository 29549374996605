import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getRoomId } from 'state/game/selectors'
import { getUsername, getIsConnected } from 'state/user/selectors'

const LanguageBtn = styled.div`
    border: 1px solid #8cd2c4;
    padding: 4px 10px;
    margin: 0 4px;
    cursor: pointer;
    background: ${({ $isActive }) => ($isActive ? '#8cd2c4' : 'none')};
    color: ${({ $isActive }) => ($isActive ? '#fff' : 'black')};
`

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #8cd2c4;
`

const RoomIndicator = ({ lng, onLngChange }) => {
    const roomId = useSelector(getRoomId)
    const username = useSelector(getUsername)
    const connected = useSelector(getIsConnected)

    return (
        <Wrapper>
            <div>
                {username} - Room ID: {roomId}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!!lng && !!onLngChange && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <LanguageBtn
                            $isActive={lng === 'en'}
                            onClick={() => onLngChange('en')}
                        >
                            EN
                        </LanguageBtn>
                        <LanguageBtn
                            $isActive={lng === 'de'}
                            onClick={() => onLngChange('de')}
                        >
                            DE
                        </LanguageBtn>
                    </div>
                )}
                <ion-icon
                    style={{
                        background: 'none',
                        color: connected ? 'green' : 'red',
                        fontSize: 22,
                        padding: 0,
                        margin: 0,
                        border: 0,
                    }}
                    name="ios-wifi"
                ></ion-icon>
            </div>
        </Wrapper>
    )
}

export default RoomIndicator
