import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'

const isLocal = window.location.href.includes('localhost')
const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    isLocal
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        : compose

const enhancer = composeEnhancers(
    applyMiddleware(thunk)
    // other store enhancers if any
)

const store = createStore(rootReducer, enhancer)

export default store
