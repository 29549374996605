import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setUsername } from 'state/user/actions'
import { getUsername } from 'state/user/selectors'
import { setRoomId } from 'state/game/actions'
import { getRoomId } from 'state/game/selectors'
import WebSocketService from 'services/WebSocketService'
import { getIsLoading } from 'state/loading/selectors'
import { updateIsLoading } from 'state/loading/actions'
import Loading from 'components/loading/Loading'
// import { useEffect } from 'react'

const Start = () => {
    const username = useSelector(getUsername)
    const roomId = useSelector(getRoomId)
    const isLoading = useSelector(getIsLoading)
    const dispatch = useDispatch()

    // for development
    // useEffect(() => {
    //     WebSocketService.connect('join', 'ducci', '123')
    // }, [])

    const createRoom = username => {
        if (!username) {
            alert('please enter username')
            return
        }

        if (username.length > 15) {
            alert('please use a name shorter than 15 chars')
            return
        }

        dispatch(updateIsLoading(true))
        WebSocketService.connect('create', username)
    }

    const joinRoom = (username, roomId) => {
        if (!username || !roomId) {
            alert('please enter username & roomId')
            return
        }

        if (username.length > 15) {
            alert('please use a name shorter than 15 chars')
            return
        }

        dispatch(updateIsLoading(true))
        WebSocketService.connect('join', username, roomId)
    }

    return (
        <div style={{ paddingTop: '50vh', textAlign: 'center' }}>
            <input
                type="text"
                value={username}
                onChange={e => dispatch(setUsername(e.target.value))}
                placeholder="enter your name *"
            />

            <br />

            <input
                type="text"
                value={roomId}
                onChange={e => dispatch(setRoomId(e.target.value))}
                placeholder="room ID (when join)"
            />

            <br />

            <button
                style={{ marginTop: 25 }}
                disabled={isLoading}
                onClick={() => {
                    localStorage.setItem('roomId', roomId)
                    joinRoom(username, roomId)
                }}
            >
                join game
            </button>
            <br />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 50,
                }}
            >
                {isLoading ? <Loading /> : <span>-- OR --</span>}
            </div>
            <button
                disabled={isLoading}
                onClick={() => createRoom(username)}
                style={{ marginTop: '2%' }}
            >
                create room
            </button>
        </div>
    )
}

export default Start
