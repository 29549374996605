import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
    getCurrentMemePath,
    getMyself,
    getPlayersWithMissingSelection,
    getRoundState,
    getPlayingPlayers,
    getAmIJury,
    getCurrentPlayer,
    getWinningPlayer,
    getLastWinner,
    getLastWinningCard,
    getPlayers,
} from 'state/game/selectors'
import gameConstants from 'state/game/constants'
import WebSocketService, { constants } from 'services/WebSocketService'
import RoomIndicator from 'components/roomIndicator/RoomIndicator'
import styled from 'styled-components'

const Card = styled.div`
    border: 1px solid #8cd2c4;
    margin: 10px;
    padding: 4px;
    background: ${({ isDisabled, isActive }) =>
        isDisabled ? 'grey' : isActive ? '#8cd2c4' : 'white'};
    color: ${({ isDisabled, isActive }) =>
        isDisabled ? 'white' : isActive ? 'white' : 'black'};
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
    cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
    line-height: 1.3;
`

function MainView() {
    const path = useSelector(getCurrentMemePath)
    const [lng, setLng] = useState('en')
    const roundState = useSelector(getRoundState)
    const { availableCards, winningMemes, selectedCard } = useSelector(
        getMyself
    )
    const playersToWaitFor = useSelector(getPlayersWithMissingSelection)
    const playingPlayers = useSelector(getPlayingPlayers)
    const amIJury = useSelector(getAmIJury)
    const currentPlayer = useSelector(getCurrentPlayer)
    const winningPlayer = useSelector(getWinningPlayer)
    const lastWinner = useSelector(getLastWinner)
    const lastWinningCard = useSelector(getLastWinningCard)

    const isEn = lng === 'en'

    const playerWithPoints = useSelector(getPlayers).map(player => ({
        username: player.username,
        points: player.winningMemes.length,
    }))

    function chooseWinning(cardId) {
        WebSocketService.emit(constants.clientType.chooseWinning, cardId)
    }

    const shuffledPlayers = playingPlayers.sort(player => {
        if (Math.random() > 0.5) {
            return -1
        }
        return 1
    })

    return (
        <div>
            <RoomIndicator lng={lng} onLngChange={lng => setLng(lng)} />
            {lastWinner && lastWinningCard && (
                <div
                    style={{
                        textAlign: 'center',
                        color: 'green',
                        marginTop: 10,
                        lineHeight: 1.3,
                    }}
                >
                    {lastWinner}:{' '}
                    {isEn ? lastWinningCard.valueEn : lastWinningCard.value}
                </div>
            )}
            {path && (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    <img
                        style={{ maxWidth: '80%', maxHeight: 300 }}
                        src={path}
                        alt=""
                    />
                </div>
            )}

            {roundState === gameConstants.ROUND_STATES.BEFORE_NEXT_TURN && (
                <div style={{ textAlign: 'center' }}>
                    {amIJury ? (
                        <button
                            onClick={() => {
                                WebSocketService.emit(
                                    constants.clientType.startNextTurn
                                )
                            }}
                        >
                            continue
                        </button>
                    ) : (
                        <div>{currentPlayer.username} can continue</div>
                    )}
                </div>
            )}

            {roundState === gameConstants.ROUND_STATES.SUGGEST_MEME_TEXT && (
                <div style={{ textAlign: 'center' }}>
                    {amIJury && <div>Its your turn. You skip</div>}
                    <div>
                        {availableCards.map(card => (
                            <Card
                                isActive={selectedCard?.id === card.id}
                                isDisabled={amIJury}
                                key={card.id}
                                onClick={() => {
                                    if (!amIJury) {
                                        WebSocketService.emit(
                                            constants.clientType.selectCard,
                                            card.id
                                        )
                                    }
                                }}
                            >
                                {isEn ? card.valueEn : card.value}
                            </Card>
                        ))}
                        <br />
                        <div style={{ marginBottom: 10 }}>Waiting for:</div>
                        {playersToWaitFor.map(player => (
                            <div
                                key={player.username}
                                style={{
                                    marginBottom: 10,
                                }}
                            >
                                {player.username}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {roundState === gameConstants.ROUND_STATES.CHOOSE_WINNING_TEXT && (
                <div style={{ textAlign: 'center' }}>
                    <div
                        style={{
                            fontSize: 16,
                            marginBottom: 10,
                        }}
                    >
                        {currentPlayer.username} will choose
                    </div>
                    {shuffledPlayers.map(player => (
                        <Card
                            isDisabled={!amIJury}
                            onClick={() =>
                                amIJury
                                    ? chooseWinning(player.selectedCard.id)
                                    : null
                            }
                            key={player.selectedCard.id}
                        >
                            {isEn
                                ? player.selectedCard.valueEn
                                : player.selectedCard.value}
                        </Card>
                    ))}
                </div>
            )}

            {roundState === gameConstants.ROUND_STATES.GAME_END && (
                <div style={{ textAlign: 'center', paddingTop: 10 }}>
                    <div style={{ fontSize: 24, color: '#8CD2C4' }}>
                        Game finished
                    </div>
                    <div
                        style={{
                            marginTop: 20,
                            fontSize: 44,
                            color: '#A3CCEC',
                            padding: 10,
                        }}
                    >
                        {winningPlayer.username} won with{' '}
                        {winningPlayer.winningMemes.length} memes!
                    </div>
                </div>
            )}

            <div
                style={{
                    borderTop: '1px solid #A3CCEC',
                    borderBottom: '1px solid #8CD2C4',
                    marginTop: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    textAlign: 'center',
                }}
            >
                {playerWithPoints.map((player, index) => {
                    const isLast = playerWithPoints.length === index + 1
                    return (
                        <span key={player.username}>
                            {' '}
                            {player.username}: {player.points}
                            {isLast ? '' : ' |'}
                        </span>
                    )
                })}
            </div>

            <div style={{ textAlign: 'center' }}>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    my won memes:
                </div>
                {winningMemes.map(meme => (
                    <div key={meme.card.id} style={{ marginBottom: 10 }}>
                        <img
                            style={{ maxWidth: '70%' }}
                            src={meme.memePath}
                            alt=""
                        />
                        <div>{isEn ? meme.card.valueEn : meme.card.value}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MainView
