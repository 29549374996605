import types from './types'

export const setUsername = username => ({
    type: types.SET_USERNAME,
    data: username,
})

export const setSocketId = socketId => ({
    type: types.SET_SOCKET_ID,
    data: socketId,
})

export const updateConnectionStatus = connected => ({
    type: types.UPDATE_CONNECTION_STATUS,
    data: connected,
})
