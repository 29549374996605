import React from 'react'
import { getPlayers, getHostId } from 'state/game/selectors'
import { useSelector } from 'react-redux'
import { getSocketId } from 'state/user/selectors'
import WebSocketService, { constants } from 'services/WebSocketService'
import { getIsLoading } from 'state/loading/selectors'
import RoomIndicator from 'components/roomIndicator/RoomIndicator'

function Lobby() {
    const players = useSelector(getPlayers)
    const hostId = useSelector(getHostId)
    const isLoading = useSelector(getIsLoading)
    const socketId = useSelector(getSocketId)
    const isHost = hostId === socketId

    return (
        <div>
            <RoomIndicator />

            <div style={{ padding: 10 }}>
                <div style={{ fontSize: 20, marginBottom: 10 }}>Players:</div>
                {players.map(player => (
                    <div
                        style={{ fontSize: 16, marginBottom: 12 }}
                        key={player.socketId}
                    >
                        {player.username}
                    </div>
                ))}
                {isHost ? (
                    <button
                        disabled={isLoading}
                        onClick={() => {
                            if (players.length >= 2) {
                                WebSocketService.emit(
                                    constants.clientType.start
                                )
                            } else {
                                alert('Total 2 players needed')
                            }
                        }}
                    >
                        Start
                    </button>
                ) : (
                    <div style={{ marginTop: 20 }}>Host can start the game</div>
                )}
            </div>
        </div>
    )
}

export default Lobby
